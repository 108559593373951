/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/_custom-icons.scss';
/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

:root {
    --privacy-policy-button-width: auto;
    --privacy-policy-button-font-size: inherit;
    --privacy-policy-button-margin: 0;
}

// higher specificity needed to override base styles
// change variables to override properties.
.PrivacyPolicyButton.PrivacyPolicyButton {
    width: var(--privacy-policy-button-width);
    font-size: var(--privacy-policy-button-font-size);
    margin: var(--privacy-policy-button-margin);
    background-color: unset;
    border: none;
    min-height: unset;
    min-width: unset;
    height: 100%;
    line-height: 1;
}
