/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/_custom-icons.scss';
/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

:root {
    --brand-info-logo-size: 150px;
}

.BrandInfo {
    padding: 7px;

    &-Logo {
        width: var(--brand-info-logo-size);
        height: var(--brand-info-logo-size);
        padding: 0;
        display: block;
        margin: 0 auto;
        max-height: 60px;
        max-width: 75px;

        @include after-mobile {
            float: left;
            margin-right: 15px;
        }
    }

    &-Description {
        margin-bottom: 3rem;
        
        h1 {
            text-align: center;
            padding-bottom: 30px;
        }

        h2 {
            margin-top: 0;
        }

        p {
            font-family: 'OpenSans', sans-serif;
            font-weight: 300;
            line-height: 22px;
            font-size: 14px;
            text-align: center;
        }

        img {
            object-fit: cover;
        }
    }
}
