/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/_custom-icons.scss';
/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/shop-by-brand-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 * @author    Helvijs Sebris <info@scandiweb.com>
 */

:root {
    --brand-list-brand-size: 80px;

    @include desktop {
        --brand-list-brand-size: 120px;
    }
}

.BrandList {
    @include desktop {
        margin-top: 45px;
    }

    @include mobile {
        margin-top: 30px;
    }

    &-List {
        display: flex;
        flex-wrap: wrap;
    }

    &-Brand {
        width: var(--brand-list-brand-size);
        height: var(--brand-list-brand-size);
        flex: 0 0 50%;
        border-bottom: 1px solid var(--primary-divider-color);
        margin-bottom: -1px;
        padding: 2rem;

        &::before {
            display: none;
        }

        @include desktop {
            flex: 0 0 19.5%;
        }
    }

    &-Link {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    &-Image {
        width: 100%;
        height: 100%;

        &:hover {
            filter: brightness(1.1);
        }
    }
}
