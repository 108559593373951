/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/_custom-icons.scss';
.ProductList {
    &-LoadButton {
        font-family: $font-abhaya-libre;
        background-color: #ffffff;
        cursor: pointer;
        font-size: 13pt;
        margin-bottom: 1.2rem;
        padding: 0.8em 0;
        text-align: center;

        @include mobile {
            margin-bottom: 75px;
            margin-left: 1.4rem;
            margin-right: 1.4rem;
            border: 1px solid black;
        }

        &_isPageLoading {
            & span {
                visibility: hidden;
                opacity: 0;
            }

            &::after {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                inset: 0;
                margin: auto;
                border: 4px solid transparent;
                border-top-color: #0a0a0a;
                border-radius: 50%;
                animation: rotate 1s ease infinite;
            }
        }
    }
}
