/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/_custom-icons.scss';
/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

.MyAccountPrivacySettings {
    margin-top: calc(var(--my-account-button-spacer) * -1);

    &-Wrapper {
        &:last-child {
            display: none;
        }
    }

    &-Title {
        border-bottom: 1px solid var(--color-my-account-privacy-settings-border-bottom);
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
    }

    &-Wrapper {
        &_isPrivacyConsents {
            min-height: 150px;
        }
    }

    .Button {
        margin: 15px 0 40px;
    }

    .PrivacyPolicyButton {
        margin: 0;
    }
}
