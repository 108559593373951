:root {
    --homepage-slider-color: #{$white};
}

.homepage-slider {
    --button-padding: 14px 20px;

    h1 {
        font-size: 6.2rem;
        text-transform: uppercase;
        color: var(--homepage-slider-color);
        margin-bottom: 3rem;

        @include mobile {

            font-size: 3rem;
            margin-bottom: 1.4rem;
        }
    }

    .Slider-Crumbs {
        bottom: 2.4rem;

        @include mobile {
            bottom: 2.8rem;
        }
    }

    .SliderWidget-Figcaption {
        text-align: center;

        @include desktop {
            padding-top: 6rem;
        }
    }
}
