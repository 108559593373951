/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/_custom-icons.scss';
.Header {
  .Header-Nav &-MinicartButtonWrapper {
    width: auto;
  }

  &-AccountBtn {
    margin-inline: 8px 16px;
  }

  .Header-Nav &-MinicartItemCount {
    @include mobile {
      inset-block-start: 4px;
    }
  }

  &-SearchBtn:hover {
    .SearchIcon {
      fill: var(--primary-base-color);
    }
  }

  &-CloseSearchBtn {
    padding-inline: 18px 6px;

    &:hover {
      cursor: pointer;
      color: var(--primary-base-color);
    }
  }

  .Header-Nav .SearchField_isVisible {
    margin-block: 0;
  }

  &-SearchBtn {
    margin-inline: 8px;

    @include mobile {
      position: absolute;
      left: 10%;
      width: auto;
    }
  }

  @include mobile {
    .SearchField {
      &-SearchIcon {
        inset-inline-start: 10px;
      }

      &-Input {
        padding-inline-start: 40px;
      }
    }
  }
}
