/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/_custom-icons.scss';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --gdpr-cookies-box-shadow-color: rgba(0, 0, 0, .3);
    --gdpr-cookies-button-padding: 5px 15px;
    --gdpr-cookies-group-scrollbar-thumb-color: #e8e8e8;
    --gdpr-cookies-group-scrollbar-track-color: #fff;
}

.GdprCookiesPopup {
    align-items: flex-start;
    justify-content: start;

    @include mobile {
        top: 0 !important;
    }

    .Popup {
        &-Content {
            border-radius: 0;
            height: 100vh;
            max-height: 100%;
            max-width: 400px;
            overflow-y: scroll;
            padding: 14px 14px 14px 14px;

            @include after-mobile {
                box-shadow: 0 0 12px 2px var(--gdpr-cookies-box-shadow-color);
                min-width: 400px;
                padding: 0 24px 0 0;
                overflow-y: hidden;
            }
        }

        &-CloseBtn {
            display: none;
        }
    }

    &-Wrapper {
        @include desktop {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
    }

    &-Actions {
        height: max-content;
        padding-top: 14px;

        @include after-mobile {
            padding: 24px 0 24px 24px;
        }
    }

    &-Buttons {
        @include mobile {
            margin-bottom: 15px;
        }

        .Button {
            margin-bottom: 15px;
            width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-Description {
        font-size: 16px;

        a {
            color: var(--gdpr-cookie-group-more-info-color);
        }
    }

    &-Groups {
        margin-bottom: 20px;

        @include desktop {
            display: flex;
            flex-direction: column;
        }

        @include after-mobile {
            direction: rtl;
            margin-left: 5px;
            padding-top: 25px;
            overflow-y: scroll;
        }

        &::-webkit-scrollbar {
            width: 4px;

            &-thumb {
                background: var(--gdpr-cookies-group-scrollbar-thumb-color);
                border-radius: 2px;
            }

            &-track {
                background-color: var(--gdpr-cookies-group-scrollbar-track-color);
                box-shadow: none;
            }
        }
    }
}
