/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/_custom-icons.scss';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --category-details-background: var(--secondary-base-color);
}

.CategoryDetails {
    display: grid;
    grid-gap: 1.2rem;
    margin-bottom: 1.2rem;

    @include mobile {
        grid-gap: 1.4rem;
        margin-bottom: 1.4rem;
        display: none;
    }

    &-Picture {
        mix-blend-mode: multiply;
        height: 100%;
        background: none;
    }

    &-Heading {
        text-transform: uppercase;
        margin-bottom: .6rem;
        font-size: 35px;
        font-weight: 400;
        text-transform: uppercase;

        @include mobile {
            font-size: 2.8rem;
            margin-bottom: .7rem;
        }
    }

    &-Description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 300;
        padding: 2.4rem;
        padding-bottom: 1rem;

        @include mobile {
            padding: 2.8rem;
        }
    }
}
