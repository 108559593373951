/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/_custom-icons.scss';
.CategoryPage {
  &-TopPanel {
    --category-page-miscellaneous-height: 70px;

    position: fixed;
    top: var(--header-total-height);
    width: 100%;
    z-index: 90;
    background: var(--color-white);
    border-bottom: 1px solid var(--primary-divider-color);
    height: var(--category-page-miscellaneous-height);
    padding-inline: 12px;
  }

  &-Title {
    text-align: center;
    padding-block-start: 8px;
  }

  &-Filters {
    display: flex;
    justify-content: space-between;
  }

  &-Wrapper {
    .CategoryPage {
      &-ProductListWrapper {
        @include mobile {
          padding-block-start: 36px;
        }
      }
    }
  }

  &-SearchKeyword {
    display: inline-block;
    text-transform: uppercase;
    color: var(--primary-base-color);
    margin-inline-start: 10px;
  }
}
